.about {
    padding: 4rem;
    padding-top: 100px;
    background: white;
  }
  
  h1 {
    text-align: center;
    padding-bottom: 40px;
  }
  
  .about__container {
    display: table;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .about__paragraph{
    position: relative;
    margin: 50px 0 45px;
    text-align: center;
    display: block;
    display: table-row;
  }
  


  .about__wrapper {
    position: relative;
    margin: 50px 0 45px;
    display:block
  }
  
  .about__items {
    margin-bottom: 30px;
  }

  .pic{
    float: left;
    margin-right: 50px;
  }

  img{
    border-radius: 25px;
  }

  @media screen and (max-width: 960px) {
    .about__container {
        font-size: 15px;
        margin-top: -150px;
        align-items: center;
    }

    .about__wrapper{
      text-align: center;
      font-size: 15px;
      margin-left: -50px;
    }

    .about__paragraph >p {
      font-size: 20px;
      text-align: center;
      overflow: hidden;
      
    }

    .pic{
      object-fit: cover;
    }

    .img{
      object-fit: cover;
    }

}

@media screen and (max-width: 768px) {
    .about__paragraph {
        font-size: 15px;
        margin-top: -100px;
        text-align: center;
        overflow: hidden;
    }

    .about__paragraph >p {
      font-size: 20px;
      text-align: center;
      overflow: hidden;
      
    }

    .about__wrapper{
      text-align: center;
      font-size: 15px;
      
      
    }


    .about > h1 {
      font-size: 15px;
      text-align: center;

    }



    .pic{
      object-fit: cover;
      width: 100%;
    }

    .img{
      object-fit: cover;
    }
}