/* h2{
    padding-left: 400px;
} */



.work_container {
    padding: 4rem;
    padding-top: 100px;
    background: white;
}
.work_section {
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
}

.work_info {
    justify-content: center;
    margin-left: 20%;
    word-wrap: break-word;
    width: 50%;
}

.work_element {
    margin-bottom: 1rem;

}

.education_header {
    padding-left: 400px;
}

.education_info{
    justify-content: center;
    text-align: center;

}

.education_info > em{
    color: 	#202020
}

.education_info > p{
    color: #202020;
    font-size: 14px;
}

.education_info > ul{
    padding-top: 10px;
}

li{
    padding-top: 10px;
}

.education_section{
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;
}

.education_container{
    padding: 4rem;
    padding-top: 1rem;
    background: white;

}

.thin_bar{
    color: #333;
    background-color: 	#D3D3D3;
    width:60%;
    border: 0;
    height:1px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.skills > h2{
    line-height: 100px;
}

.skills{
    background: white;
    padding-bottom: 100px;
    max-width: 100%;
    justify-content: center;
}

* {box-sizing:border-box}


.skills_info{
    position: absolute;
    max-width: 520px;
    width: 70%;
    padding-bottom: 800px;

}

.skills_section{
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;
    
}

.skills_container{
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;
}

@media screen and (min-width: 1080px) {
    .skillsGrid{
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 50px;
      grid-auto-rows: auto;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .skillsGrid img {
        height: 100px;
        width: 100px;
    }
    .skills_item_container {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        justify-content: center;
        align-items: center;
  }
}