video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;


}

.hero-container{

    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0, 0.2);
    object-fit: contain;
}

.hero-container > h1{
    color: white;
    font-size: 100px;
    margin-top: -100px;
}

.hero-container > p{
    color: white;
    font-size: 32px;
    margin-top: 8px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}



@media screen and (max-width: 960px) {
    .hero-container > h1{
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container > h1{
        font-size: 50px;
        margin-top: -100px;
    }
}





