.form {
    padding: 4rem;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;

  }
  
  h1 {
    text-align: center;
  }

  .form__container{
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 420px;
    width: 100%;
    padding: 20px;
    text-align: center;
    margin: 0 auto;
    background: white;
    border-radius: 25px;
  }
  
  .form__contact {
    background: white;
    
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }